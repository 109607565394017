<template>
<div class="tourial-app">
    <Splash :visible="loading" :innerHeight="innerHeight" />
    <vs-sidebar v-if="!isMobile && !$route.meta.print && !$route.meta.hiddenMenu" absolute reduce v-model="active" open style="padding-top: 16px; border-top-right-radius: 0;">
        <vs-sidebar-item id="HomePage">
            <template #icon>
                <img class="active" src="./assets/icons/menu/home_active.png" alt="menu-aboutus-active" />
                <img class="inactive" src="./assets/icons/menu/home.png" alt="menu-aboutus" />
            </template>
            {{ $t('common.homePage') }}
        </vs-sidebar-item>
        <vs-sidebar-item id="Esplora">
            <template #icon>
                <img class="active" src="./assets/icons/menu/explore_active.png" alt="menu-explore-active" />
                <img class="inactive" src="./assets/icons/menu/explore.png" alt="menu-explore" />
            </template>
            {{ $t('common.explore') }}
        </vs-sidebar-item>
        <vs-sidebar-item id="Viaggi" :disabled="user == null">
            <template #icon>
                <img class="active" src="./assets/icons/menu/trips_active.png" alt="menu-trips-active" />
                <img class="inactive" src="./assets/icons/menu/trips.png" alt="menu-trips" />
            </template>
            {{ $t('common.trips') }}
        </vs-sidebar-item>
        <vs-sidebar-item id="Preferiti" :disabled="user == null">
            <template #icon>
                <img class="active" src="./assets/icons/menu/favourites_active.png" alt="menu-favourites-active" />
                <img class="inactive" src="./assets/icons/menu/favourites.png" alt="menu-favourites" />
            </template>
            {{ $t('common.favourites') }}
        </vs-sidebar-item>

        <vs-sidebar-item id="Profilo" :disabled="user == null">

            {{ $t('common.profile') }}

            <template #icon>
                <vs-avatar badge-color="danger" badge-position="top-right" size="32" square>
                    <img class="active" src="./assets/icons/menu/profile_active.png" alt="menu-profile-active" />
                    <img class="inactive" src="./assets/icons/menu/profile.png" alt="menu-profile" />

                    <template #badge v-if="notificationsCount > 0">
                        {{ notificationsCount > 10 ? '10+' : notificationsCount }}
                    </template>
                </vs-avatar>
            </template>
        </vs-sidebar-item>

        <vs-sidebar-item id="ContactUs">
            <template #icon>
                <img class="active" src="./assets/icons/menu/contact_active.png" alt="menu-contactus-active" />
                <img class="inactive" src="./assets/icons/menu/contact.png" alt="menu-contactus" />
            </template>
            {{ $t('common.contactUs') }}
        </vs-sidebar-item>

        <vs-sidebar-item id="Blog">
            <template #icon>
                <img class="active" src="./assets/icons/menu/blog_active.png" alt="menu-blog-active" />
                <img class="inactive" src="./assets/icons/menu/blog.png" alt="menu-blog" />
            </template>
            {{ $t('common.blog') }}
        </vs-sidebar-item>

        <vs-sidebar-group v-if="user !== null && user !== false && user.is_admin">
            <template #header>
                <vs-sidebar-item arrow>
                    <template #icon>
                        <img class="active" src="./assets/icons/menu/admin_active.png" />
                        <img class="inactive" src="./assets/icons/menu/admin.png" />
                    </template>
                    Administration
                </vs-sidebar-item>
            </template>

            <vs-sidebar-item id="ADM_POI">
                <template #icon>
                    <img class="active" src="./assets/icons/menu/admin_pois_active.png" />
                    <img class="inactive" src="./assets/icons/menu/admin_pois.png" />
                </template>
                POIs
            </vs-sidebar-item>

            <vs-sidebar-item id="ADM_Targets">
                <template #icon>
                    <img class="active" src="./assets/icons/menu/admin_target_active.png" />
                    <img class="inactive" src="./assets/icons/menu/admin_target.png" />
                </template>
                Targets
            </vs-sidebar-item>

            <vs-sidebar-item id="ADM_Notifications">
                <template #icon>
                    <img class="active" src="./assets/icons/menu/admin_notifications_active.png" />
                    <img class="inactive" src="./assets/icons/menu/admin_notifications.png" />
                </template>
                Notifications
            </vs-sidebar-item>

            <vs-sidebar-item id="ADM_Partner">
                <template #icon>
                    <img class="active" src="./assets/icons/menu/admin_partner_active.png" />
                    <img class="inactive" src="./assets/icons/menu/admin_partner.png" />
                </template>
                Partner
            </vs-sidebar-item>

            <vs-sidebar-item id="ADM_Link">
                <template #icon>
                    <img class="active" src="./assets/icons/menu/admin_link_active.png" />
                    <img class="inactive" src="./assets/icons/menu/admin_link.png" />
                </template>
                Links
            </vs-sidebar-item>

            <vs-sidebar-item id="ADM_Analytics">
                <template #icon>
                    <img class="active" src="./assets/icons/menu/admin_analytics_active.png" />
                    <img class="inactive" src="./assets/icons/menu/admin_analytics.png" />
                </template>
                Analytics
            </vs-sidebar-item>

            <vs-sidebar-item id="ADM_Coupons">
                <template #icon>
                    <img class="active" src="./assets/icons/menu/admin_coupons_active.png" />
                    <img class="inactive" src="./assets/icons/menu/admin_coupons.png" />
                </template>
                Coupons
            </vs-sidebar-item>

            <vs-sidebar-item id="ADM_GooglePlaces">
                <template #icon>
                    <img class="active" src="./assets/icons/menu/google_active.png" />
                    <img class="inactive" src="./assets/icons/menu/google.png" />
                </template>
                Google Places
            </vs-sidebar-item>

            <vs-sidebar-item id="ADM_Reviews">
                <template #icon>
                    <img class="active" src="./assets/icons/menu/admin_reviews_active.png" />
                    <img class="inactive" src="./assets/icons/menu/admin_reviews.png" />
                </template>
                Recensioni
            </vs-sidebar-item>
        </vs-sidebar-group>

    </vs-sidebar>
    <template v-else>
        <MobileNavbar v-if="!$route.meta.print && !$route.meta.hiddenMenu" :user="user" :userLoaded="userLoaded"/>
        <MobileMenu v-if="mobileMenuVisible" :user="user" :innerHeight="innerHeight" @close="() => {mobileMenuVisible = false}" :userLoaded="userLoaded" />
    </template>

    <BreadCumb v-if="isMobile && showBreadcumb && !mobileMenuVisible" :name="backName" :back="back" />

    <transition name="fade">
        <RouterView style="width: 100%; overflow-y: auto;" :style="{paddingTop: ($route.meta.breadcumb ? (30+30) : 30) + 'px'}" :user="user" :userLoaded="userLoaded" :innerHeight="innerHeight" />
    </transition>
    <CookieConsent v-if="!$route.meta.print" />
</div>
</template>

<script>
import MobileNavbar from './components/MobileNavbar.vue'
import MobileMenu from './components/MobileMenu.vue'
import Splash from './components/Splash.vue'
import BreadCumb from './components/BreadCumb.vue'
import {
    apiCall
} from './utils/ApiMiddleware'
import {
    GlobalEventEmitter
} from './utils/GlobalEventEmitter'
import CookieConsent from './components/CookieConsent.vue'
export default {
    name: 'App',
    components: {
        MobileNavbar,
        MobileMenu,
        Splash,
        BreadCumb,
        CookieConsent
    },

    data() {
        return {
            isMobile: false,
            active: 'explore',
            activeProxy: null,
            prevRoute: null,
            mobileMenuVisible: false,
            history: [],
            loading: true,
            showBreadcumb: false,
            user: null,
            userLoaded: false,
            innerHeight: window.innerHeight,
            backName: '',
            notificationsCount: 0,

            expiringNotificationShown: false
        }
    },
    beforeDestroy() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onResize, {
                passive: true
            })
        }
        // removes event listener
        GlobalEventEmitter.$off('loadUserMeta', this.getUser)
        GlobalEventEmitter.$off('getNotificationsCount', this.getNotificationsCount);
        GlobalEventEmitter.$off('showExpiringNotification', this.showExpiringNotification);

    },

    mounted() {
        this.getUser();
        this.onResize()
        window.addEventListener('resize', this.onResize, {
            passive: true
        })

        setTimeout(() => {
            this.loading = false
        }, 1500);

    },

    methods: {
        async getUser() {
            // use apiCall to call the login api
            let response = await apiCall('GET', '/users/info', null, true);

            // if status code is 200, show a success message and redirect to the explore page, otherwise show an error message
            if (response.status == 200) {
                const lastTransactions = await this.getLastTransactions();
                if (lastTransactions.length > 0) {
                    // used for other logics, we need this to understand if he has active subscription
                    response.data.subscription = lastTransactions.find(t => t.status == 'ACTIVE');
                } else {
                    response.data.subscription = null;
                }

                response.data.transactions = lastTransactions;
                this.user = response.data;
                this.getNotificationsCount();
            } else {
                this.user = null;
            }

            this.userLoaded = true;
        },

        async getLastTransactions() {
            const response = await apiCall('GET', '/subscriptions', {
                limit: 20,
                order: 'desc(end_time)',
                only_active: false
            });
            if (response.status == 200) {
                if (response.data.data != null && response.data.data.length > 0) {
                    return response.data.data;
                } else {
                    return [];
                }
            }
            return [];
        },

        async getNotificationsCount() {
            // use apiCall to call the login api
            const response = await apiCall('GET', '/notifications', {
                only_unread: true
            });

            // if status code is 200, show a success message and redirect to the explore page, otherwise show an error message
            if (response.status == 200) {
                if (response.data.data != null) {
                    this.notificationsCount = response.data.data.length;
                    if (response.data.after != null) {
                        this.notificationsCount++; // 11 will be shown as 10+
                    }
                } else {
                    this.notificationsCount = 0;
                }
            }
        },

        onResize() {
            this.isMobile = window.innerWidth < 600
            this.innerHeight = window.innerHeight;
        },
        back() {
            var n = this.$NAVIGATION_HISTORY[this.$NAVIGATION_HISTORY.length - 1];
            if (n == undefined) {
                this.$router.replace({
                    path: '/explore'
                })
                return;
            }
            this.$router.replace({
                path: n.fullPath
            })
        },

        canIBeHere() {

            if (!this.userLoaded) {
                return true;
            }
            if (this.$route.meta.auth == true && this.user == null) {

                this.$router.push({
                    name: 'Accedi'
                })
                return false;
            } else if (this.$route.meta.preauth == true && this.user != null) {
                this.$router.push({
                    name: 'Esplora'
                })
                return false;
            }

            return true;
        },

        async showExpiringNotification() {
            if(!this.userLoaded || this.user == null){
                // retry
                setTimeout(this.showExpiringNotification, 500);
                return;
            }

            if(this.user.is_admin){
                return; // admin
            }

            if(this.user.is_partner){
                return; // partner
            }

            if(this.user.subscription != null){
                return; // abbonato
            }

            if(this.expiringNotificationShown){
                return; // già mostrata per questa istanza di App
            }

            // se ha una transazione ALMENO UNA in uno di questi stati vuol dire che almeno una volta è stato premium, quindi non mostrare la notifica
            // EXPIRED_AFTER_DEACTIVATION, EXPIRED, DEACTIVATED,RENEWED,ACTIVE
            const statuses = ['EXPIRED_AFTER_DEACTIVATION', 'EXPIRED', 'DEACTIVATED', 'RENEWED', 'ACTIVE'];
            if(this.user.transactions != null && this.user.transactions.length > 0){
                // check if has a transaction with one of the statuses
                if(this.user.transactions.find(t => statuses.includes(t.status)) != null){
                    this.user.hadPremium = true;
                    return;
                }
            }

            // TODO: utente con transazione di rinnovo, non mostrare la notifica, significa che ha già acquistato almeno una volta

            this.expiringNotificationShown = true;


            let days = this.$TRIAL_DAYS - Math.ceil(Math.abs((new Date()) - (new Date(this.user.created_at)))/ (1000 * 60 * 60 * 24));
            if(days < 1){
                days = 0;
            }

            const expiringNotification = this.$vs.notification({
                title: this.$t('explore.expiring.title', {
                    days: days.toString()
                }),
                text: this.$t('explore.expiring.caption'),
                color: 'primary',
                position: 'top-right',
                duration: 10000,
                progress: 'auto',
                classNotification: 'tourial-expiring-notification',
                onClick: () => {
                    expiringNotification.setLoading(true);
                    setTimeout(() => {
                        this.$router.push({name: 'Sottoscrizione'});
                        expiringNotification.close();
                    }, 500);
                }
            })
        }
    },

    created() {
        // adds the event listener function that will handle the event
        GlobalEventEmitter.$on('loadUserMeta', this.getUser)
        GlobalEventEmitter.$on('getNotificationsCount', this.getNotificationsCount);
        GlobalEventEmitter.$on('showExpiringNotification', this.showExpiringNotification);
    },


    watch: {
        '$route'(n) {
            console.log(n.name)
            if (!this.canIBeHere()) {
                return;
            }
            this.activeProxy = n.meta.menu;
            this.showBreadcumb = n.meta.breadcumb;

            var r = this.$NAVIGATION_HISTORY[this.$NAVIGATION_HISTORY.length - 1];

            if (r != undefined) {
                this.backName = this.$t('routes.' + r.name);
            } else {
                this.backName = 'Esplora';
            }
        },

        user(n) {
            if (n != null && n != false && n.language != null) {
                this.$i18n.locale = n.language.name.toLowerCase();
            }
            this.canIBeHere();
        },
        userLoaded(n) {
            this.canIBeHere();

            // if i'm in homepage redirect to /explore, only if user is being loaded and user is defined
            if(n && this.user != null && this.$route.name == 'HomePage'){
                this.$router.push({name: 'Esplora'});
            }
        },
        activeProxy(n) {
            this.active = n;
        },
        active(n) {
            if (n == this.activeProxy) { // already navigated
                return;
            }
            this.$router.push({
                name: n
            })
        }
    }
}
</script>

<style>
:root {
    --primary: #EF7E18;
    --grey-75: #D0C9D6;
    --natural-grey-2: #3F3356;
    --vs-text: 0, 0, 0 !important;
}

body.blured {
    background-image: url(./assets/images/blured-map.png);
}

html {
    height: 100%;
    overflow-y: hidden;
    overflow: hidden;
    overscroll-behavior: none;
}

body {

    background-repeat: no-repeat;
    background-size: cover;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    padding: 0 !important;
    margin: 0 !important;
    height: 100%;
    touch-action: pan-x pan-y;
    overflow-y: hidden;

}

.bold {
    font-weight: 700;
}

.light {
    color: var(--grey-75);
}

.center {
    text-align: center;
}

.vs-button--dark {
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: #000 !important;
}

.vs-button--dark:hover {
    box-shadow: 0px 10px 20px -10px rgba(var(--vs-color), 0.2) !important;
}

.vs-button--transparent {
    color: #000 !important;
}

.vs-button__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3px;
    font-family: 'montserrat' !important;
    font-weight: 500 !important;
}

.vs-input-content input {
    height: 48px !important;
}

.vs-button--size-large .vs-button__content {
    padding: 13px 15px !important;
}

form {
    width: 100%;
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 0.25s ease-out;
}

.slide-up-enter-from {
    opacity: 0;
    transform: translateY(30px);
}

.slide-up-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}

a {
    color: var(--primary);
}

.slideout-panel {
    z-index: 2000 !important;
}

.slideout {
    width: 25% !important;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 0;
    overflow-x: hidden;

}

.blog-container .slideout {
    width: 60% !important;
}

.vs-sidebar__item img {
    width: 32px;
    height: auto;
}

.vs-sidebar__item .active {
    display: none;
}

.vs-sidebar__item.active .active {
    display: block;
}

.vs-sidebar__item.active .inactive {
    display: none !important;
}

.slideout-panel-bg {
    z-index: 100 !important;
}

.vs-select__label {
    font-size: 14px;
    color: var(--natural-grey-2);
    margin-bottom: 10px;
}

h1 {
    font-size: 2.5rem;
}

textarea {
    font-family: 'montserrat' !important;
}

/*
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
    font-size: 1rem;
    font-family: 'montserrat' !important;

}
*/

.tourial-app {
    height: 100%;
}

.vs-dialog-content.blur {
    -webkit-backdrop-filter: saturate(130%) blur(15px) !important;
    backdrop-filter: saturate(130%) blur(15px) !important;

}

.vs-sidebar__item__text-tooltip {
    background: #FFFFFF;
    /* Dropshadow 2 */

    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.27) !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-right: 10px;
    padding-left: 10px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 50px !important;

}

@page {
    size: 7in 9.25in;
    margin: 0mm;
}

.vs-tooltip {
    padding: 0 !important;
}

.vs-tooltip.left {
    margin-right: -10px !important;
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    background-color: white;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 3px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
}

.fade-enter-active,
.fade-leave-active {
    transition-property: opacity;
    transition-duration: .25s;
}

.fade-enter-active {
    transition-delay: .25s;
}

.fade-enter,
.fade-leave-active {
    opacity: 0
}

@font-face {
    font-family: "lg";
    src: url(./assets/fonts/lg.ttf) format("truetype");
}

.vs-select__options {
    z-index: 2100 !important;
}

.vs-sidebar__item .vs-avatar-content:hover .vs-avatar {
    box-shadow: none !important;
}

.vs-sidebar__item .vs-avatar-content:hover img {
    transform: unset !important;
}

.vs-sidebar__item .vs-avatar {
    box-shadow: none !important;
    background: unset !important;
}

.vs__dropdown-toggle {
    background: unset !important;
    border: unset !important;
}

.vs__dropdown-menu {
    padding: 0 !important;
}

.vs--single .vs__selected {
    font-size: 1rem !important;
    margin-left: 4px !important;
    font-family: 'Montserrat';
}

input.vs__search {
    margin-left: 4px !important;
    font-size: 1rem !important;

}

.vs-select__input.simple {
    font-size: 1rem !important;
    padding-left: 10px !important;
}

/* MOBILE */
@media (max-width: 600px) {
    .slideout {
        width: 100% !important;
        height: unset !important;
        max-height: 70vh !important;
        padding-bottom: 100px;
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;
        z-index: 2100 !important;
    }

    .slideout-panel-bg {
        z-index: 2000 !important;
    }

    input:not(.vs__search),
    .vs-select__chips__chip {
        height: 50px !important;
        font-size: 18px !important;
    }
}

.vs-checkbox-mask:before {
    border: 2px solid #b1b1b1 !important;
}

.tourial-expiring-notification h4 {
    font-weight: 100 !important;
}

.tourial-expiring-notification {
    z-index: 10000!important;
}

.tourial-expiring-notification .vs-notification {
    max-height: unset!important;
}
</style>
